const DestinationDetails = () => {
  return (
    <div className="bg-slate-900">
      <div className="md:flex items-start py-6 px-4 text-white">
        <div className="mb-4 md:mb-0 md:w-1/3 lg:w-1/2">
          <a className="text-sm text-sky-500" href="#">
            Resorts & Casino
          </a>
          <h1 className="mb-4 text-4xl font-bold">Grand Tropical Resort</h1>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolores, sed non eaque, provident autem voluptate iusto in tempore sit quam
            porro, odio similique? Autem dolores recusandae quod amet doloribus perferendis.
          </p>

          <div className="mt-4 flex space-x-4 text-sm text-slate-500">
            <span className="flex">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                <path
                  fill-rule="evenodd"
                  d="M9.69 18.933l.003.001C9.89 19.02 10 19 10 19s.11.02.308-.066l.002-.001.006-.003.018-.008a5.741 5.741 0 00.281-.14c.186-.096.446-.24.757-.433.62-.384 1.445-.966 2.274-1.765C15.302 14.988 17 12.493 17 9A7 7 0 103 9c0 3.492 1.698 5.988 3.355 7.584a13.731 13.731 0 002.273 1.765 11.842 11.842 0 00.976.544l.062.029.018.008.006.003zM10 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z"
                  clip-rule="evenodd"
                />
              </svg>
              Nasau, Bahamas
            </span>

            <span className="flex">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                <path
                  fill-rule="evenodd"
                  d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                  clip-rule="evenodd"
                />
              </svg>
              <a href="" className="text-indigo-500">
                3.9/5
              </a>
              , (174)
            </span>
          </div>

          <div className="mt-6">
            <button className="w-full lg:w-auto mb-2 lg:mb-0 rounded-lg border border-indigo-600 bg-indigo-600 px-6 py-3 text-sm transition-colors hover:border-indigo-800 hover:bg-indigo-800">
              Book now
            </button>
            <button className="w-full lg:w-auto ml-0 mb-2 lg:mb-0 lg:ml-2 rounded-lg border border-indigo-600 bg-transparent px-6 py-3 text-sm transition-colors hover:border-indigo-800 hover:bg-indigo-800">
              Check availability
            </button>
          </div>

          <div>
            <div className="flex">
              <div></div>
            </div>
          </div>
        </div>

        <div className="md:ml-6 md:w-2/3 lg:w-1/2">
          <div className="col-start-1 col-end-3 row-start-1 grid gap-4 sm:mb-6 sm:grid-cols-4 lg:col-start-2 lg:row-span-6 lg:row-end-6 lg:mb-0 lg:gap-6">
            <img
              className="h-60 w-full rounded-lg object-cover sm:col-span-4 lg:col-span-full"
              src="https://source.unsplash.com/random/?beach"
              alt=""
            />
            <img
              className="hidden h-60 w-full rounded-lg object-cover sm:col-span-2 sm:block lg:col-span-2 lg:row-start-2 lg:h-52"
              src="https://source.unsplash.com/random/?tropical"
              alt=""
            />
            <img
              className="hidden h-60 w-full rounded-lg object-cover sm:col-span-2 sm:block lg:col-span-2 lg:row-start-2 lg:h-52"
              src="https://source.unsplash.com/random/?palms"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DestinationDetails;
