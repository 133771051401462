import React from 'react';

const DropdownMenu = ({prop}: { prop: any }) => {
    return (
        <div className="flex h-screen w-full items-center justify-center">
            <div className="relative z-10 w-[250px] rounded-lg bg-slate-800 text-[0.8125rem] leading-5 text-slate-700 shadow-xl shadow-black/5 ring-1 ring-slate-700/10">
                <ul>
                    <li className="border-t border-slate-400/20 px-3.5 py-3">
                        <div className="flex items-center rounded-md transition-colors duration-100 bg-sky-500 p-1.5 text-white cursor-pointer">
                            <svg className="mr-2 h-5 w-5 flex-none stroke-white" fill="none" viewBox="0 0 24 24" strokeWidth="2"><path strokeLinecap="round" strokeLinejoin="round" d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"></path></svg>Activity
                        </div>
                        <div className="flex items-center rounded-md transition-colors duration-100 hover:bg-sky-500 p-1.5 text-white cursor-pointer">
                            <svg className="mr-2 h-5 w-5 flex-none stroke-white" fill="none" viewBox="0 0 24 24" strokeWidth="2"><path strokeLinecap="round" strokeLinejoin="round" d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"></path></svg>Settings
                        </div>
                    </li>
                    <li className="border-t border-slate-400/20 px-3.5 py-3">
                        <div className="flex items-center rounded-md transition-colors duration-100 hover:bg-sky-500 p-1.5 text-white cursor-pointer">
                            <svg className="mr-2 h-5 w-5 flex-none stroke-white" fill="none" viewBox="0 0 24 24" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path></svg>Integrations
                        </div>
                        <div className="flex items-center rounded-md transition-colors duration-100 hover:bg-sky-500 p-1.5 text-white cursor-pointer">
                            <svg className="mr-2 h-5 w-5 flex-none stroke-white" fill="none" viewBox="0 0 24 24" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"></path></svg>Dark mode
                        </div>
                        <div className="flex items-center rounded-md transition-colors duration-100 hover:bg-sky-500 p-1.5 text-white cursor-pointer">
                            <svg className="mr-2 h-5 w-5 flex-none stroke-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16" /></svg
                            >Add account
                        </div>
                        <div className="flex items-center rounded-md transition-colors duration-100 hover:bg-sky-500 p-1.5 text-white cursor-pointer">
                            <svg className="mr-2 h-5 w-5 flex-none stroke-white" fill="none" viewBox="0 0 24 24" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"></path></svg>Logout
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default DropdownMenu;
