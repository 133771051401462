import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { gradientDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import COMPONENTS from './items';

const ItemPreview = ({ item }: any) => {
  const [showCode, setShowCode] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);

  const copyCodeToClipboard = async (code: string) => {
    try {
      await navigator.clipboard.writeText(code);
      setAlertVisible(true);

      setTimeout(() => {
        setAlertVisible(false);
      }, 2500);
    } catch (err) {
      console.log('There was an error trying to copy the code to your clipboard');
    }
  };

  return (
    <div className="relative">
      <header className="flex items-center justify-between mb-3">
        <h1 className="text-xl font-semibold">{item.title}</h1>

        {process.env.NODE_ENV === 'development' ? (
          <Link className="bg-indigo-600 px-4 py-2 rounded-md text-white" to={'/edit?id=' + item.id}>
            edit
          </Link>
        ) : null}

        <ul className="flex items-center justify-end">
          <li className="mr-3 flex flex-row items-center space-x-4">
            { alertVisible ? <span className={"text-green-700 font-bold"}>&#9989; Copied</span> : null }
            <button
              className={'border py-[12px] px-3 lg:px-4 flex items-center justify-center rounded-lg border-primary'}
              onClick={() => copyCodeToClipboard(item.code)}
              title={"Copy to clipboard"}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15.666 3.888A2.25 2.25 0 0013.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 01-.75.75H9a.75.75 0 01-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 01-2.25 2.25H6.75A2.25 2.25 0 014.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 011.927-.184"
                />
              </svg>
            </button>
          </li>
          <li>
            <button
              className={
                'border py-[10px] px-3 lg:px-4 flex items-center justify-center rounded-l-lg border-primary mr-[-1px]' +
                (!showCode && ' bg-slate-700 text-white')
              }
              onClick={() => setShowCode(false)}
            >
              <span className="sm:mr-1">
                <svg viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-4 h-3 stroke-current">
                  <path
                    d="M1 7C1 7 4 1 9.25 1C14.5 1 17.5 7 17.5 7C17.5 7 14.5 13 9.25 13C4 13 1 7 1 7Z"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M9.25 9.25C10.4926 9.25 11.5 8.24264 11.5 7C11.5 5.75736 10.4926 4.75 9.25 4.75C8.00736 4.75 7 5.75736 7 7C7 8.24264 8.00736 9.25 9.25 9.25Z"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </span>
              <span className="hidden sm:block">Preview</span>
            </button>
          </li>
          <li>
            <button
              className={
                'border py-[10px] px-3 lg:px-4 flex items-center justify-center rounded-r-lg text-body-color border-[#d8dfff]  dark:text-dark-text dark:border-body-color dark:border-opacity-50' +
                (showCode && ' bg-slate-700 text-white')
              }
              onClick={() => setShowCode(true)}
            >
              <span className="sm:mr-1">
                <svg viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-4 h-3 stroke-current">
                  <path d="M10 9L14 5L10 1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M5 1L1 5L5 9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </span>
              <span className="hidden sm:block">Code</span>
            </button>
          </li>
        </ul>
      </header>

      <div className="rounded-md shadow-md item-preview-content border bg-white overflow-hidden">
        {!showCode && React.createElement(COMPONENTS[item.component], {})}

        {showCode && (
          <SyntaxHighlighter language="htmlbars" style={gradientDark} showLineNumbers={true}>
            {item.code}
          </SyntaxHighlighter>
        )}
      </div>
    </div>
  );
};

export default ItemPreview;
