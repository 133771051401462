const TravelDeals = () => {
  return (
    <div className="relative flex flex-col justify-center overflow-hidden bg-cover py-6 sm:py-12">
      <div className="mx-auto flex w-2/3 max-w-[760px] rounded-3xl bg-sky-100 p-6">
        <div className="w-full">
          <div className="flex flex-col-reverse justify-between md:flex-row">
            <div>
              <h1>Hello, Jane</h1>
              <div>
                <div className="text-3xl font-semibold">Today's deals</div>
                <p className="text-sm text-gray-500">Everyday deals tailored to your travel preferences</p>
              </div>
            </div>

            <img className="w-16 rounded-lg md:w-auto" src="https://source.unsplash.com/75x75?profile" alt="" />
          </div>

          <div className="mt-12 mb-4 flex justify-between text-sm font-semibold">
            <div className="flex space-x-4">
              <button className="text-sky-500 transition-colors">Home</button>
              <button className="md:hidden">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-4 w-4">
                  <path
                    fill-rule="evenodd"
                    d="M16.28 11.47a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 011.06-1.06l7.5 7.5z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
              <button className="hidden transition-colors hover:text-sky-500 md:inline-block">Adventure</button>
              <button className="hidden transition-colors hover:text-sky-500 md:inline-block">Tour</button>
            </div>

            <button className="transition-colors hover:text-sky-500">View all</button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            <div className="relative col-span-1">
              <div className="rop-0 absolute right-0 z-10 m-2 rounded-full bg-white/90 p-2 text-sm">4.5</div>
              <img
                className="w-full rounded-3xl transition-all hover:cursor-pointer hover:brightness-75"
                src="https://source.unsplash.com/350x300?cocktail"
                alt=""
              />
              <a href="#" className="mt-3 block font-medium leading-3 transition-colors hover:text-sky-500">
                Tropical Resort
              </a>
              <span className="text-xs font-bold text-orange-500">Florida, USA</span>
            </div>

            <div className="relative col-span-1">
              <div className="rop-0 absolute right-0 z-10 m-2 rounded-full bg-white/90 p-2 text-sm">4.5</div>
              <img
                className="w-full cursor-pointer rounded-3xl transition-all hover:brightness-75"
                src="https://source.unsplash.com/350x300?beach,tropical"
                alt=""
              />
              <a href="#" className="mt-3 block font-medium leading-3 transition-colors hover:text-sky-500">
                Tropical Beach
              </a>
              <span className="text-xs font-bold text-orange-500">Mexico</span>
            </div>
          </div>

          <div className="mt-8 flex space-x-5">
            <img
              className="h-full w-1/3 cursor-pointer rounded-3xl transition-all hover:brightness-75"
              src="https://source.unsplash.com/350x300?cocktail,beach"
              alt=""
            />
            <div>
              <a href="#" className="text-xl font-semibold transition-colors hover:text-sky-500">
                Special offers for you
              </a>
              <p className="text-sm font-medium text-sky-500">Diving in the ocean in Turkey</p>
              <p className="mt-2 text-sm">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. At voluptatum non officiis nemo dolorem odit ad voluptas totam
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TravelDeals;
