import {useEffect, useState} from 'react';
import Header from '../components/Header';
import ItemPreview from '../components/ItemPreview';
import {get} from 'superagent';
import {API} from '../constants/core';
import Footer from '../components/Footer';
import CategoryBar from '../components/CategoryBar';

const LandingPage = () => {
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);

    useEffect(() => {
        const getComponents = async () => {
            try {
                const result = await get(`${API}/components`);
                setItems(result.body);
            } catch (err) {
                console.log('Could not retrieve components');
                // window.location.reload();
            } finally {
                setLoading(false);
            }
        };

        getComponents();
    }, []);

    return (
        <>
            {/* <Navbar /> */}
            <Header/>

            {false && <CategoryBar onFilteredItems={setItems}/>}

            <div className="items-container mx-auto px-6 max-w-[1248px]">
                {
                    loading && !items.length ?
                        (
                            <div className={"text-center font-bold text-xl py-10"}>Loading components...</div>
                        ) :
                        (<div className="my-6">
                                {items.map((item, i) => (
                                    <div className="mb-10 w-full" key={i}>
                                        <ItemPreview item={item}/>
                                    </div>
                                ))}
                            </div>
                        )
                }
            </div>

            <Footer/>
        </>
    );
};

export default LandingPage;
