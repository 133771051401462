const Footer = () => {
  return (
    <div className="text-center bg-slate-800 p-6 text-white">
      <h2 className="text-xl">TWUI.io</h2>
      <p>Free Tailwind components for your React apps</p>
      <p>
        Beautiful illustrations provided by{' '}
        <a className="text-indigo-500" href="https://undraw.co" target="_blank">
          unDraw
        </a>
      </p>
      <p className="text-gray-400 text-sm">Copyright 2022 &copy;</p>
    </div>
  );
};

export default Footer;
