import DestinationDetails from "./DestinationDetails";
import DestinationDetailsCard from "./DestinationDetailsCard";
import ProfileCard from "./ProfileCard";
import ProfileCard2 from "./ProfileCard2";
import TravelDeals from "./TravelDeals";
import TwitterStyleProfileHeader from "./TwitterStyleProfileHeader";
import TWUIHero from "./TWUIHero";
import DropdownMenu from "./DropdownMenu";

const COMPONENTS: any = {
    DestinationDetails: DestinationDetails,
    DestinationDetailsCard: DestinationDetailsCard,
    TWUIHero: TWUIHero,
    TwitterStyleProfileHeader: TwitterStyleProfileHeader,
    ProfileCard: ProfileCard,
    ProfileCard2: ProfileCard2,
    TravelDeals: TravelDeals,
    DropdownMenu: DropdownMenu
};

export default COMPONENTS;
