const CATEGORIES: { id: string, label: string; }[] = [
    {
        id: 'cards',
        label: 'Cards'
    },
    {
        id: 'profiles',
        label: 'Profiles'
    },
    {
        id: 'page-sections',
        label: 'Page sections'
    },
    {
        id: 'sidebars',
        label: 'Sidebars'
    },
    {
        id: 'sign-in-and-reg',
        label: 'Sign-ins and registrations'
    },
    {
        id: 'forms',
        label: 'Forms'
    },
    {
        id: 'lists',
        label: 'Lists'
    }

];

export default CATEGORIES;
