const TWUIHero = () => {
  return (
    <header className="bg-white dark:bg-gray-900">
      <div className="flex max-w-screen-xl px-4 py-8 mx-auto lg:py-16">
        <div className="place-self-center w-full lg:w-7/12">
          <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white">
            Free Tailwind components for your React apps
          </h1>
          <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
            Browse a large collection of gorgeous components. Copy the code or download as a file, ready to be used in your project. No external
            dependencies, just Tailwind.
          </p>
        </div>
        <div className="hidden lg:mt-0 lg:w-4/12 lg:flex text-center">
          <img src="/assets/programmer.svg" alt="mockup" />
        </div>
      </div>
    </header>
  );
};

export default TWUIHero;
