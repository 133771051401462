const TwitterStyleProfileHeader = () => {
  return (
    <div className="overflow-hidden bg-slate-900 text-white border-x w-2/3 mx-auto">
      <header className="w-full border-b">
        <div className="flex p-4">
          <div className="mt-1">
            <h1 className="text-xl font-bold mb-0 leading-4">Micorsoft Azure</h1> <span className="text-xs text-gray-500">400k followers</span>
          </div>
        </div>
        <img src="https://source.unsplash.com/random/1000x250" alt="" />
        <div className="flex flex-col md:flex-row justify-center md:justify-end items-center relative py-4 px-4 mb-7 -top-20 md:top-0">
          <div className="md:absolute md:block md:left-5 md:-top-20 border-4 border-slate-900 rounded-full">
            <img className="rounded-full" src="https://source.unsplash.com/150x150?profile" alt="" />
          </div>
          <div className="flex space-x-4">
            <button>Options</button> <button>Message</button> <button className="bg-white text-black px-4 py-2 rounded-3xl">Follow</button>
          </div>
        </div>
        <div className="px-4 -mt-16 md:mt-0">
          <h2 className="text-xl font-bold mb-0 leading-4">Microsoft Azure</h2> <span className="text-xs text-gray-500">@Azure</span>
          <p className="mt-4">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea explicabo perspiciatis atque at nobis aliquid ut sed harum numquam?
            Doloremque, veritatis. Dicta at unde distinctio provident veniam, minus officiis sit.
          </p>
          <div className="text-gray-500 text-sm my-2">
            <span>Redmond, WA</span> &bull; Joined Oct 2008
          </div>
          <div className="text-gray-500 text-sm">
            <span>
              <span className="text-white font-extrabold">21</span> following
            </span>
            &bull;
            <span>
              <span className="text-white font-extrabold">957.4k</span> followers
            </span>
          </div>
        </div>
      </header>
    </div>
  );
};

export default TwitterStyleProfileHeader;
