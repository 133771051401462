import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import SubmitPage from '../pages/SubmitPage';

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
    errorElement: <h1>Page not found</h1>,
  },
  {
    path: '/submit',
    element: <SubmitPage />,
    errorElement: <h1>Could not load page</h1>,
  },
  {
    path: '/edit',
    element: <SubmitPage />,
    errorElement: <h1>Could not load page</h1>,
  },
]);

export default router;
