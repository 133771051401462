import React, { useEffect, useState } from 'react';
import router from './router';
import { RouterProvider } from 'react-router-dom';

function App() {
  useEffect(() => {
    // setSidenavState(window.location.pathname !== '/');
  }, []);

  return (
    <div className="App">
      <main className={'w-full'}>
        <RouterProvider router={router} />
      </main>
    </div>
  );
}

export default App;
