import React, { useEffect, useState } from 'react';
import { useLocation, redirect } from 'react-router-dom';
import { get, patch, post } from 'superagent';
import CATEGORIES from '../constants/categories';
import { API } from '../constants/core';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const SubmitPage = () => {
  const [title, setTitle] = useState('');
  const [component, setComponent] = useState('');
  const [category, setCategory] = useState(CATEGORIES[0].id);
  const [code, setCode] = useState('');
  const [isEdit, setIsEdit] = useState(false);

  if (process.env.NODE_ENV === 'production') {
    window.location.href = '/';
  }

  const query = useQuery();

  useEffect(() => {
    if (query.get('id')) {
      setIsEdit(true);

      const getComponent = async () => {
        const result = await get(`${API}/component/${query.get('id')}`);

        if (result.body) {
          setTitle(result.body.title);
          setComponent(result.body.component);
          setCategory(result.body.category);
          setCode(result.body.code);
        }
      };
      getComponent();
    }
  }, [isEdit]);

  const saveComponent = async () => {
    try {
      const result = await post(`${API}/save`).send({
        title,
        component,
        code,
        category,
      });
      console.log(result.body);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex">
      <div className="my-12 mx-auto w-2/3">
        <label className="block" htmlFor="title">
          Title
        </label>
        <input className="w-full" value={title} type="text" name="title" id="title" onChange={(event) => setTitle(event.target.value)} />

        <br />
        <label className="block" htmlFor="component">
          Component
        </label>
        <input
          className="w-full"
          value={component}
          type="text"
          name="component"
          id="component"
          onChange={(event) => setComponent(event.target.value)}
        />

        <br />
        <label className="block" htmlFor="category">
          Category
        </label>
        <select name="category" id="category" value={category} onChange={(event) => setCategory(event.target.value)}>
          {CATEGORIES.map((cat, i) => (
            <option key={i} value={cat.id}>
              {cat.label}
            </option>
          ))}
        </select>

        <br />
        <label className="block" htmlFor="code">
          Code snippet
        </label>
        <textarea
          className="w-full"
          value={code}
          onChange={(event) => setCode(event.target.value)}
          name="code"
          id="code"
          cols={30}
          rows={20}
        ></textarea>

        <br />
        <button className="block w-full bg-sky-600 px-6 py-3 text-white rounded-lg mt-4" onClick={() => saveComponent()}>
          submit
        </button>
      </div>
    </div>
  );
};

export default SubmitPage;
